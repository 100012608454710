




















// Define the component in class-style
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { value } from "@/models/CustomTypes";

@Component
export default class FinancialInstrumentAutocomplete extends Vue {
  @Prop({ default: "Instrument finansowy" }) label: string;
  @Prop({ default: "" }) queryParam: string;
  @Prop({ default: () => [] }) rules: [];
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) returnObject: boolean;
  @Prop({ default: "" }) icon: boolean;
  @Prop({ default: null }) year: string;
  @VModel({ type: [Number, String, Object] }) localValue: value;
  items: Record<string, string>[] = [];

  async loadData() {
    this.items = [];
    if (this.year === null) {
      const maxYearIndex = new Date().getFullYear() - 1999 + 1;
      for (let j = 21; j <= maxYearIndex; j++) {
        for (let i = 1; i <= 12; i++) {
          if (i < 10) {
            this.items.push({ name: `BASE_M-0${i}-${j}` });
            this.items.push({ name: `GAS_BASE_M-0${i}-${j}` });
          } else {
            this.items.push({ name: `BASE_M-${i}-${j}` });
            this.items.push({ name: `GAS_BASE_M-${i}-${j}` });
          }
        }
        for (let i = 1; i <= 4; i++) {
          this.items.push({ name: `BASE_Q-${i}-${j}` });
          this.items.push({ name: `GAS_BASE_Q-${i}-${j}` });
        }
        this.items.push({ name: `BASE_Y-${j}` });
        this.items.push({ name: `GAS_BASE_Y-${j}` });
        this.items.push({ name: `PMOZE_A-${j}` });
        this.items.push({ name: `PMOZE_BIO-${j}` });
        this.items.push({ name: `PMEF_F-${j}` });
        this.items.push({ name: `TGEgasDA-${j}` });
        this.items.push({ name: `TGEgasID-${j}` });
        this.items.push({ name: `TGe24-${j}` });
      }
    } else {
      const twoDigitYear = this.year.substring(this.year.length - 2, this.year.length);
      for (let i = 1; i <= 12; i++) {
        if (i < 10) {
          this.items.push({ name: `BASE_M-0${i}-${twoDigitYear}` });
          this.items.push({ name: `GAS_BASE_M-0${i}-${twoDigitYear}` });
        } else {
          this.items.push({ name: `BASE_M-${i}-${twoDigitYear}` });
          this.items.push({ name: `GAS_BASE_M-${i}-${twoDigitYear}` });
        }
      }
      for (let i = 1; i <= 4; i++) {
        this.items.push({ name: `BASE_Q-${i}-${twoDigitYear}` });
        this.items.push({ name: `GAS_BASE_Q-${i}-${twoDigitYear}` });
      }
      this.items.push({ name: `BASE_Y-${twoDigitYear}` });
      this.items.push({ name: `GAS_BASE_Y-${twoDigitYear}` });
      this.items.push({ name: `PMOZE_A` });
      this.items.push({ name: `PMOZE_BIO` });
      this.items.push({ name: `PMEF_F` });
      this.items.push({ name: `TGEgasDA` });
      this.items.push({ name: `TGEgasID` });
      this.items.push({ name: `TGe24` });
    }
  }

  @Watch("year")
  onYearChange(newVal: value, oldVal: value) {
    if (newVal != oldVal) {
      this.loadData();
    }
  }

  @Watch("queryParam")
  onQueryParamChange(newVal: value, oldVal: value) {
    if (newVal != oldVal) {
      this.loadData();
    }
  }

  @Watch("disabled")
  onDisabledChange(newVal: value, oldVal: value) {
    if (newVal != oldVal) {
      this.loadData();
    }
  }

  async created() {
    if (!this.disabled) {
      await this.loadData();
    }
  }
}
